import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Card from "./Card.js";
import Backoffice from "./Backoffice.js";
import LoginForm from "./LoginForm.js";

import "./App.css";
import "semantic-ui-css/semantic.min.css";
import "./Card.css";


function App() {
  console.log(process.env.REACT_APP_API_URL)

  const isAuthenticated = localStorage.getItem("authenticated") || false;

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/backoffice/" element={isAuthenticated ? <Backoffice /> : <LoginForm />} />
          <Route path="/cards/:id" element={<Card />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/" element={isAuthenticated ? <Backoffice /> : <LoginForm />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
