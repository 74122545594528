import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { personsList } from "./ExampleData";
import config from "./config";

const Card = () => {
  const [person, setPerson] = useState(undefined);
  const [htmlContent, setHtmlContent] = useState("");

  let { id } = useParams();

  useEffect(() => {
    console.log("ID", id);
    const fetchData = async () => {
      try {
        console.log("Esegui la richiesta delle CARD");
        // Esegui la richiesta GET utilizzando Axios
        const response = await axios.get(`${config.apiUrl}/cards`);
        // Aggiorna lo stato con i dati ottenuti dalla risposta
        const myData = response.data.map((e) => {
          return {
            ...e,
            image_url: `${config.apiUrl}/uploads/card_` + e.id_card + ".jpg",
            logo_url: `${config.apiUrl}/uploads/logo_` + e.id_card + ".png",
          };
        });
        const p = myData.filter((p) => p.id_card === parseInt(id));
        if (p.length > 0) {
          console.log("p", p[0]);
          setPerson(p[0]);
        } else {
          console.log("No record found with ID ", id);
        }
      } catch (err) {
        // Gestisci eventuali errori
        console.log(err.message);
      }
    };
    fetchData();
  }, [id]);

  console.log("> ID", id);
  console.log("> Person", person);
  console.log("> htmlContent", htmlContent);

  /*

  return (
    <div>
      <h1>{person.name}</h1>
      <div className="card">
        <img
          src={person.image_url}
          alt="Avatar"
          style={{ "border-radius": "10%", width: "150px", padding: "10px" }}
        ></img>
        <div className="card-inner">
          <h2>{person.contact_name}</h2>
          <h3>{person.occupation}</h3>
          <p>{person.information}</p>
          <button>Informazioni</button>
        </div>
      </div>
    </div>
  );
  */

  const getVCFData = async () => {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/vcf/standard.vcf"
    );

    console.log("RES VCAD: >>>>>>>>>>", res.data);

    const vcfData = res.data;

    console.log("VCF: ", vcfData);

    const replacedVCF = vcfData
      .replace(/{contact_name}/g, person.contact_name)
      .replace(/{information}/g, person.information)
      .replace(/{occupation}/g, person.occupation)
      .replace(/{occupation2}/g, person.occupation)
      .replace(/{instagram}/g, person.instagram ? person.instagram : "")
      .replace(/{twitter}/g, person.twitter ? person.twitter : "")
      .replace(/{facebook}/g, person.facebook ? person.facebook : "")
      .replace(/{phone}/g, person.phone ? person.phone : "")
      .replace(/{email}/g, person.email ? person.email : "")
      .replace(/{address}/g, person.address ? person.address : "");

    const vcfReplacedURL =
      "data:text/plain;charset=utf-8," + encodeURIComponent(replacedVCF);
    console.log("VCF REPLACED: ", vcfReplacedURL);
    return vcfReplacedURL;
  };

  useEffect(() => {
    console.log("Fetch HTML");
    const fetchHtmlContent = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL +
            "/templates/" +
            person.card_template +
            "/index.html"
        );
        const templateContent = response.data;

        console.log("Dati HTML: ", response.data);

        // Estrai i valori dall'oggetto JSON
        const replacedContent = templateContent
          .replace(/{contact_name}/g, person.contact_name)
          .replace(/{image_url}/g, person.image_url)
          .replace(/{logo_url}/g, person.logo_url)
          .replace(/{information}/g, person.information)
          .replace(/{occupation}/g, person.occupation)
          .replace(
            /{occupation2}/g,
            person.occupation2 ? person.occupation2 : ""
          )
          .replace(/{notes}/g, person.notes ? person.notes : "")
          .replace(/{instagram}/g, person.instagram ? person.instagram : "")
          .replace(/{twitter}/g, person.twitter ? person.twitter : "")
          .replace(/{facebook}/g, person.facebook ? person.facebook : "")
          .replace(/{phone}/g, person.phone ? person.phone : "")
          .replace(/{email}/g, person.email ? person.email : "")
          .replace(
            /{address_map}/g,
            person.address_map ? person.address_map : ""
          )
          .replace(
            /{background_color}/g,
            person.background_color ? person.background_color : ""
          )
          .replace(/{address}/g, person.address ? person.address : "")
          .replace(/{vcf_data}/g, await getVCFData());

        setHtmlContent(replacedContent);
      } catch (error) {
        console.error("Errore nella richiesta del file HTML:", error);
      }
    };

    fetchHtmlContent();
  }, [person]);

  return person ? (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  ) : (
    <div></div>
  );
};

export default Card;
