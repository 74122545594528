import { useState, useEffect } from "react";
import { Button, Header, Image, Modal, Table } from "semantic-ui-react";
import axios from "axios";
import config from "./config";
import { personsList } from "./ExampleData";
import ModalDialog from "./ModalDialog";
import EditCard from "./EditCard";

const Backoffice = () => {
  const [persons, setPersons] = useState([]);
  const [openCard, setOpenCard] = useState(false);
  const [currentEditCard, setCurrentEditCard] = useState({});
  const [askForNew, setAskForNew] = useState(false);
  const [askForDelete, setAskForDelete] = useState(false);

  const getCardLocation = (url, card) => {
    if (url.search("backoffice") > 0) {
      const newUrl = url.replace("backoffice", card);
      console.log(newUrl);
      return newUrl;
    } else {
      return url + card;
    }
  };

  console.log("open Card: ", openCard);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Esegui la richiesta delle CARD");
        // Esegui la richiesta GET utilizzando Axios
        const response = await axios.get(`${config.apiUrl}/cards`);
        // Aggiorna lo stato con i dati ottenuti dalla risposta
        const myData = response.data.map((e) => {
          return {
            ...e,
            image_url: `${config.apiUrl}/uploads/card_` + e.id_card + ".jpg",
            logo_url: `${config.apiUrl}/uploads/logo_` + e.id_card + ".png",
          };
        });
        setPersons(myData);
      } catch (err) {
        // Gestisci eventuali errori
        console.log(err.message);
      }
    };
    fetchData();
  }, []);

  const sendDeleteCard = () => {
    console.log("DELETE: ", currentEditCard);
    axios
      .delete(`${config.apiUrl}/card/${currentEditCard.id_card}`)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error deleting data: ", error);
      });
  };

  const sendAddCard = () => {
    console.log("PUT");
    axios
      .put(`${config.apiUrl}/card/`, {
        contact_name: "NEW",
        occupation: "NEW occupation",
        information: "NEW Information",
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error put data: ", error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <EditCard
        open={openCard}
        closeFunction={() => setOpenCard(false)}
        card={currentEditCard}
        applyCardFunction={(c) => {
          console.log("Aggiorno i dati di person: ", c);
          setPersons(
            persons.map((e) => {
              if (e.id_card === c.id_card) {
                console.log("Aggiorno i dati di ", e, "in ", c);
                setCurrentEditCard(c);
                return c;
              } else {
                return e;
              }
            })
          );
        }}
      />
      <ModalDialog
        open={askForNew}
        noFunction={() => setAskForNew(false)}
        yesFunction={() => {
          setAskForNew(false);
          console.log("Aggiungo il record");
          sendAddCard();
        }}
        title="Aggiungi Card"
        text="Aggiungere una nuova Card?"
      />
      <ModalDialog
        open={askForDelete}
        noFunction={() => setAskForDelete(false)}
        yesFunction={() => {
          setAskForDelete(false);
          console.log("Elimino il record");
          sendDeleteCard();
        }}
        title="Eliminare Card"
        text={`Eliminare la Card di ${currentEditCard.contact_name}?`}
      />
      <Table basic center celled collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Dati</Table.HeaderCell>
            <Table.HeaderCell>Occupazione</Table.HeaderCell>
            <Table.HeaderCell>
              <Button
                primary
                onClick={() => {
                  setAskForNew(true);
                }}
              >
                Aggiungi
              </Button>
              <Button
                secondary
                onClick={() => {
                  localStorage.removeItem("authenticated");
                  window.location.href = "/login/";
                }}
              >
                Logout
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {persons.map((p) => {
            return (
              <Table.Row>
                <Table.Cell>{p.id_card}</Table.Cell>
                <Table.Cell>
                  <Header as="h4" image>
                    <Image
                      src={p.image_url}
                      style={{ width: "90px" }}
                      rounded
                      size="mini"
                    />
                    {" - - - "}
                    <Image
                      src={p.logo_url}
                      style={{ width: "60px" }}
                      rounded
                      size="mini"
                    />
                    <Header.Content>
                      {p.contact_name}
                      <Header.Subheader>{p.occupation}</Header.Subheader>
                      <Header.Subheader>
                        <br />
                      </Header.Subheader>
                      <Header.Subheader>{p.card_template}</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  {p.information == null || p.information.length < 50
                    ? p.information
                    : p.information.substring(0, 50) + "..."}
                  <br />
                  {p.notes == null || p.notes.length < 50
                    ? p.notes
                    : p.notes.substring(0, 50) + "..."}
                </Table.Cell>
                <Table.Cell>
                  <Button
                    primary
                    onClick={() => {
                      console.log("Set Open Card Clicked: ", p);
                      setCurrentEditCard(p);
                      setOpenCard(true);
                    }}
                  >
                    Modifica
                  </Button>
                  <Button
                    primary
                    onClick={() => {
                      window.open(
                        getCardLocation(
                          window.location.href,
                          "cards/" + p.id_card
                        )
                      );
                    }}
                  >
                    Test
                  </Button>
                  <Button
                    secondary
                    onClick={() => {
                      console.log("Delete Card pressed: ", p);
                      setCurrentEditCard(p);
                      setAskForDelete(true);
                    }}
                  >
                    Elimina
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default Backoffice;
