import React from "react";
import { useState } from "react";
import { Button, Form, Grid, Header, Image, Segment } from "semantic-ui-react";

const LoginForm = () => {
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem(localStorage.getItem("authenticated") || false)
  );

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          Log-in
        </Header>
        <Form size="large">
          <Segment stacked>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="E-mail address"
              onChange={(e) => setUsername(e.target.value)}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              color="teal"
              fluid
              size="large"
              onClick={() => {
                console.log("Login...");
                if (
                  username === "stefanomagnini@gmail.com" &&
                  password === "magno123!"
                ) {
                  console.log("Login Ok");
                  setAuthenticated(true);
                  localStorage.setItem("authenticated", true);
                  window.location.href = "/backoffice/";
                } else {
                  console.log("Login Error ", username);
                  alert("Login Errato")
                  setAuthenticated(false);
                  localStorage.removeItem("authenticated");
                }
              }}
            >
              Login
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default LoginForm;
