import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Header,
  Image,
  Modal,
  Input,
  TextArea,
  Form,
  Dropdown,
} from "semantic-ui-react";
import axios from "axios";
import config from "./config";
import QRCode from "react-qr-code";
import { toPng } from "html-to-image";
import { saveAs } from "file-saver";
// import { SliderPicker } from "react-color";
import { HexColorPicker } from "react-colorful";

function EditCard(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const [selectedFileLogo, setSelectedFileLogo] = useState(null);
  const [previewUrlLogo, setPreviewUrlLogo] = useState(null);

  const [cardData, setCardData] = useState({});
  const [templatesList, setTemplateList] = useState({ files: [] });

  const qrCodeRef = useRef();

  useEffect(() => {
    console.log("useEffect EditCard >>>> setCardData con ", props.card);
    setCardData(props.card);
  }, [props.card, props.open]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("EditCard: Esegui la richiesta dei Templates");
        // Esegui la richiesta GET utilizzando Axios
        const response = await axios.get(`${config.apiUrl}/templates-list/`);
        // Aggiorna lo stato con i dati ottenuti dalla risposta
        const myData = response.data;
        console.log("EditCard - Templates: ", myData);
        setTemplateList(myData);
      } catch (err) {
        // Gestisci eventuali errori
        console.log(err.message);
      }
    };
    fetchData();
  }, [props.card, props.open]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Leggi il file e visualizza l'anteprima
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      setSelectedFile(file);
    }
  };

  const handleFileChangeLogo = (event) => {
    const file = event.target.files[0];

    // Leggi il file e visualizza l'anteprima
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrlLogo(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      setSelectedFileLogo(file);
    }
  };

  const downloadQRCode = () => {
    // Ottieni il nodo del componente QR Code
    const qrCodeNode = qrCodeRef.current;

    // Converti la parte del DOM in un'immagine
    toPng(qrCodeNode).then((blob) => {
      // Salva l'immagine come file
      saveAs(blob, "qrcode.png");
    });
  };

  const storeCardData = () => {
    console.log("EditCard - POST: ", cardData);
    axios
      .post(`${config.apiUrl}/card/`, cardData)
      .then((response) => {
        console.log("EditCard - POST RESPONSE: ", response.data);
      })
      .catch((error) => {
        console.error("Error post data: ", error);
      });
  };

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile);

      axios
        .post(`${config.apiUrl}/upload?card_id=${props.card.id_card}`, formData)
        .then((response) => {
          console.log("EditCard - UPLOAD RESPONSE: ", response.data);
        })
        .catch((error) => {
          console.error("Error uploading image: ", error);
        });
    } else {
      console.error("Seleziona un file prima di inviare.");
    }
  };

  const handleUploadLogo = () => {
    if (selectedFileLogo) {
      const formData = new FormData();
      formData.append("image", selectedFileLogo);

      axios
        .post(`${config.apiUrl}/upload?card_id=${props.card.id_card}`, formData)
        .then((response) => {
          console.log("EditCard - UPLOAD LOGO RESPONSE: ", response.data);
        })
        .catch((error) => {
          console.error("Error uploading logo: ", error);
        });
    } else {
      console.error("Seleziona un file prima di inviare.");
    }
  };

  const templatesListOptions = templatesList.files.map((template, index) => ({
    key: index,
    text: template,
    value: template,
  }));

  return (
    <Modal open={props.open}>
      <Modal.Header>Dettaglio ({cardData.id_card})</Modal.Header>
      <Modal.Content>
        <p>
          {!previewUrl && (
            <Image size="medium" src={cardData.image_url} wrapped />
          )}
          {previewUrl && <Image size="medium" src={previewUrl} wrapped />}
        </p>
        <input type="file" onChange={handleFileChange} />
        <button onClick={handleUpload}>Upload Image</button>
      </Modal.Content>
      <Modal.Content>
        <p>
          {!previewUrlLogo && (
            <Image size="small" src={cardData.logo_url} wrapped />
          )}
          {previewUrlLogo && (
            <Image size="small" src={previewUrlLogo} wrapped />
          )}
        </p>
        <input type="file" onChange={handleFileChangeLogo} />
        <button onClick={handleUploadLogo}>Upload Logo</button>
      </Modal.Content>
      <Modal.Content>
        <Modal.Description>
          <Header>
            Nome:
            <Input
              value={cardData.contact_name}
              onChange={(e, { value }) => {
                setCardData({ ...cardData, contact_name: value });
              }}
            ></Input>
          </Header>
          <p>
            Occupazione:
            <Input
              value={cardData.occupation}
              onChange={(e, { value }) => {
                setCardData({ ...cardData, occupation: value });
              }}
            ></Input>
            Occupazione2:
            <Input
              value={cardData.occupation2}
              onChange={(e, { value }) => {
                setCardData({ ...cardData, occupation2: value });
              }}
            ></Input>
          </p>
          <p>
            Email:
            <Input
              value={cardData.email}
              onChange={(e, { value }) => {
                setCardData({ ...cardData, email: value });
              }}
            ></Input>
            Telefono:
            <Input
              value={cardData.phone}
              onChange={(e, { value }) => {
                setCardData({ ...cardData, phone: value });
              }}
            ></Input>
            Map URL:
            <Input
              value={cardData.address_map}
              onChange={(e, { value }) => {
                setCardData({ ...cardData, address_map: value });
              }}
            ></Input>
          </p>
          <p>
            Facebook:
            <Input
              value={cardData.facebook}
              onChange={(e, { value }) => {
                setCardData({ ...cardData, facebook: value });
              }}
            ></Input>
            Twitter:
            <Input
              value={cardData.twitter}
              onChange={(e, { value }) => {
                setCardData({ ...cardData, twitter: value });
              }}
            ></Input>
            Instagram:
            <Input
              value={cardData.instagram}
              onChange={(e, { value }) => {
                setCardData({ ...cardData, instagram: value });
              }}
            ></Input>
          </p>
          <p>
            Indirizzo:
            <Form>
              <TextArea
                value={cardData.address}
                onChange={(e, { value }) => {
                  setCardData({ ...cardData, address: value });
                }}
              ></TextArea>
            </Form>
          </p>
          <p>
            Informazioni:
            <Form>
              <TextArea
                value={cardData.information}
                onChange={(e, { value }) => {
                  setCardData({ ...cardData, information: value });
                }}
              ></TextArea>{" "}
            </Form>
          </p>
          <p>
            Note:
            <Form>
              <TextArea
                value={cardData.notes}
                onChange={(e, { value }) => {
                  setCardData({ ...cardData, notes: value });
                }}
              ></TextArea>{" "}
            </Form>
          </p>
          <p>
            Template Card:
            <Dropdown
              placeholder="Template"
              search
              selection
              options={templatesListOptions}
              value={cardData.card_template}
              onChange={(e, { value }) => {
                setCardData({ ...cardData, card_template: value });
              }}
            />
          </p>
          <br />
        </Modal.Description>
        <br />
        <HexColorPicker
          color={
            cardData.background_color ? cardData.background_color : "black"
          }
          onChange={(color) => {
            const newCardData = { ...cardData, background_color: color };
            console.log("EditCard - IMPOSTO IL COLORE: ", newCardData);
            setCardData(newCardData);
          }}
        />
        <br />
        <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 64,
            width: "100%",
          }}
        >
          <div ref={qrCodeRef}>
            {" "}
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={window.location.origin + "/cards/" + cardData.id_card}
              viewBox={`0 0 256 256`}
            />
          </div>
          <Button onClick={() => downloadQRCode()}>Download</Button>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            setCardData({});
            setPreviewUrl(null);
            setPreviewUrlLogo(null);
            props.closeFunction();
          }}
        >
          Nope
        </Button>
        <Button
          content="OK!"
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            console.log("EditCard: OK PRESSED - SEND TO SERVER: ", cardData);
            if (previewUrl) handleUpload();
            if (previewUrlLogo) handleUploadLogo();
            storeCardData();
            props.applyCardFunction(cardData);
            setCardData({});
            setPreviewUrl(null);
            props.closeFunction();
          }}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
}

export default EditCard;
