import React from 'react'
import {
  ModalContent,
  ModalActions,
  Button,
  Header,
  Icon,
  Modal,
} from 'semantic-ui-react'

function ModalDialog(props) {
  return (
    <Modal
      closeIcon
      open={props.open}
      onClose={() => props.noFunction(false)}
    >
      <Header icon='add' content={props.title} />
      <ModalContent>
        <p>
          {props.text}
        </p>
      </ModalContent>
      <ModalActions>
        <Button color='red' onClick={() => props.noFunction(false)}>
          <Icon name='remove' /> No
        </Button>
        <Button color='green' onClick={() => props.yesFunction(false)}>
          <Icon name='checkmark' /> Si
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default ModalDialog
